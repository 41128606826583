const falsy = 'n/a'
const dl = window.dataLayer || false
const currentURL = window.location.href

let indexViewed = []
export const viewPromoGTM = (swiper) => {
    if (!dl) return

    const currIndex = swiper.activeIndex

    if (!indexViewed.includes(currIndex)) {
        const el = swiper.slides[currIndex]
        const itemTitle = el.querySelector('.hero-home__title')?.textContent
        dl.push({ ecommerce: null })
        dl.push({
            event: 'view_promotion',
            ecommerce: {
                items: [
                    {
                        creative_name: el.dataset.creativeName || falsy,
                        creative_slot: currIndex + 1,
                        item_name: itemTitle || falsy,
                    },
                ],
                coupon: el.dataset.coupon || falsy,
            },
        })

        indexViewed.push(currIndex)
    }
}
export const selectPromoGMT = (swiper) => {
    if (!dl) return

    const slides = swiper.slides
    slides.forEach((slide) => {
        const cta = slide.querySelector('.hero-home__btn')
        cta.addEventListener('click', () => {
            const currIndex = swiper.activeIndex
            const el = swiper.slides[currIndex]
            const itemTitle = el.querySelector('.hero-home__title')?.textContent
            dl.push({ ecommerce: null })
            dl.push({
                event: 'select_promotion',
                ecommerce: {
                    items: [
                        {
                            creative_name: el.dataset.creativeName || falsy,
                            creative_slot: currIndex + 1,
                            item_name: itemTitle || falsy,
                        },
                    ],
                    coupon: el.dataset.coupon || falsy,
                },
            })
        })
    })
}

export const ctaGTM = () => {
    if (!dl) return

    const ctaTriggers = document.querySelectorAll('[data-gtm-action="cta"]')
    if (!ctaTriggers) return
    ctaTriggers.forEach((ctaTrigger) => {
        ctaTrigger.addEventListener('click', () => {
            dl.push({
                event: 'evento_automatico',
                eventCategory: ctaTrigger.dataset.gtmCategory || falsy,
                eventAction: 'cta',
                eventLabel: ctaTrigger.dataset.gtmLabel || falsy,
            })
        })
    })
}

// TODO: check
export const contactFormGTM = ({ element, data, validationError }) => {
    if (!dl) return

    if (data) {
        // Enviado
        dl.push({ formSuccess: null })
        dl.push({
            event: 'formulario_enviado',
            formSuccess: {
                eventCategory: 'formulario',
                eventLabel: currentURL,
                nombre_formulario: element.dataset.gtmName || falsy,
                timestamp_custom: new Date().getTime(),
                me_interesa_form: data.Unidad_de_Negocio__c || falsy,
                producto_form: data.Familia_de_Producto__c || falsy,
            },
        })

        return
    }

    if (validationError) {
        // Error validación de campos
        dl.push({ formValidationError: null }) // Limpia el objeto previo. Necesario para evitar duplicación en GTM
        dl.push({
            event: 'error_formulario',
            formValidationError: {
                eventCategory: 'formulario',
                eventAction: validationError,
                eventLabel: currentURL,
                nombre_formulario: element.dataset.gtmName,
            },
        })

        return
    }

    // Error servidor
    dl.push({ formValidationError: null })
    dl.push({
        event: 'error_formulario',
        formValidationError: {
            eventCategory: 'formulario',
            eventAction: 'server error',
            eventLabel: currentURL,
            nombre_formulario: element.dataset.gtmName || falsy,
        },
    })
}

export const sparePartGTM = ({ element, action, step, validationError }) => {
    if (!dl) return

    const eventLabel = currentURL
    const nombre_formulario = element.dataset.gtmName || falsy

    if (action == 'changed step') {
        const getEventName = () => {
            switch (step) {
                case 1:
                    return 'formulario_add_info'
                case 2:
                    return 'formulario_add_contact'
                case 3:
                    return 'formulario_add_message'
            }
        }

        dl.push({
            event: getEventName(),
            eventCategory: 'formulario_repuestos',
            nombre_formulario,
            timestamp_custom: new Date().getTime(),
            eventLabel,
        })
    }

    if (action == 'error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario',
            formValidationError: {
                eventCategory: 'formulario_repuestos',
                eventAction: validationError || 'server error',
                eventLabel,
                nombre_formulario,
                step_form: step,
            },
        })
    }

    if (action == 'success') {
        dl.push({
            event: 'formulario_enviado',
            formSuccess: {
                eventCategory: 'formulario_repuestos',
                nombre_formulario,
                eventLabel,
                timestamp_custom: new Date().getTime(),
            },
        })
    }
}

export const dirtyFormGTM = () => {
    if (!dl) return

    const forms = document.querySelectorAll('form[data-gtm-name]')
    if (!forms.length) return
    forms.forEach((form) => {
        const inputs = form.querySelectorAll('.form__control')
        if (!inputs) return

        let wasTriggered = false

        inputs.forEach((input) => {
            input.addEventListener('change', () => {
                if (wasTriggered) return

                dl.push({
                    event: 'comienzo_formulario',
                    eventCategory: 'formulario',
                    eventLabel: currentURL,
                    nombre_formulario: form.dataset.gtmName || falsy,
                })

                wasTriggered = true
            })
        })
    })
}

export const sparePartViewDetailGTM = (sparePart) => {
    if (!dl) return

    dl.push({ ecommerce: null }) // Limpia el objeto previo. Necesario para evitar duplicación en GTM
    dl.push({
        event: 'select_item',
        ecommerce: {
            items: [
                {
                    item_brand: sparePart.brand || falsy,
                    item_id: sparePart.code || falsy,
                    item_name: sparePart.name || falsy,
                    affiliation: 'repuestos',
                    item_category: sparePart.category || falsy,
                    // item_list_name: '{lista en la que se encuentra}',
                },
            ],
        },
    })
}

export const sparePartAddToCartGTM = (sparePart) => {
    if (!dl) return

    dl.push({ ecommerce: null }) // Limpia el objeto previo. Necesario para evitar duplicación en GTM
    dl.push({
        event: 'add_to_cart',
        ecommerce: {
            items: [
                {
                    item_brand: sparePart.brand || falsy,
                    item_id: sparePart.code || falsy,
                    item_name: sparePart.name || falsy,
                    affiliation: 'repuestos',
                    item_category: sparePart.category || falsy,
                    // item_list_name: '{lista en la que se encuentra}',
                },
            ],
        },
    })
}

export const nextToQuoterFormGTM = (getCartProducts) => {
    if (!dl) return

    const button = document.querySelector('#next-to-quoter-form')
    if (!button) return

    button.addEventListener('click', () => {
        const cartProducts = getCartProducts()

        const mappedCartProducts = cartProducts.map((sparePart) => ({
            item_brand: sparePart.brand || falsy,
            item_id: sparePart.code || falsy,
            item_name: sparePart.name || falsy,
            affiliation: 'repuestos',
            item_category: sparePart.category || falsy,
            // item_list_name: '{lista en la que se encuentra}',
        }))
        dl.push({ ecommerce: null }) // Limpia el objeto previo. Necesario para evitar duplicación en GTM
        dl.push({
            event: 'begin_checkout',
            ecommerce: {
                items: mappedCartProducts,
            },
        })
    })
}

// TODO: check
export const webinarFormGTM = (element, action, fieldsWithErrors) => {
    if (!dl) return

    const nombre_formulario = element.dataset.gtmName || falsy
    const eventLabel = currentURL
    const eventCategory = 'formulario_webinars'

    if (action == 'success') {
        dl.push({ formSuccess: null })
        dl.push({
            event: 'formulario_enviado',
            formSuccess: {
                eventCategory,
                nombre_formulario,
                eventLabel,
            },
        })
    }

    if (action == 'validation error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario_validacion',
            formValidationError: {
                eventCategory,
                eventAction: fieldsWithErrors || 'validation error',
                eventLabel,
                nombre_formulario,
            },
        })
    }

    if (action == 'error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario',
            formValidationError: {
                eventCategory,
                eventAction: fieldsWithErrors || 'server error',
                eventLabel,
                nombre_formulario,
            },
        })
    }
}

// TODO: check
export const downloadFormGTM = (element, action, fieldsWithErrors) => {
    if (!dl) return
    const nombre_formulario = element.dataset.gtmName || falsy
    const eventLabel = currentURL
    const eventCategory = 'formulario_descarga'

    if (action == 'success') {
        dl.push({ formSuccess: null })
        dl.push({
            event: 'formulario_enviado',
            formSuccess: {
                eventCategory,
                nombre_formulario,
                eventLabel,
            },
        })
    }

    if (action == 'validation error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario_validacion',
            formValidationError: {
                eventCategory,
                eventAction: fieldsWithErrors || 'validation error',
                eventLabel,
                nombre_formulario,
            },
        })
    }

    if (action == 'error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario',
            formValidationError: {
                eventCategory,
                eventAction: fieldsWithErrors || 'server error',
                eventLabel,
                nombre_formulario,
            },
        })
    }
}

export const sullairGoSuscriptionFormGTM = (element, action) => {
    if (!dl) return

    const nombre_formulario = element.dataset.gtmName || falsy
    const eventLabel = currentURL
    const eventCategory = 'formulario_solicitar_usuarios_sullairgo'

    if (action == 'success') {
        dl.push({ formSuccess: null })
        dl.push({
            event: 'formulario_enviado',
            formSuccess: {
                eventCategory: 'formulario_solicitar_usuarios_sullairgo',
                nombre_formulario: 'otros',
                eventLabel: currentURL,
            },
        })
    }

    if (action == 'validation error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario_validacion',
            formValidationError: {
                eventCategory,
                eventAction: 'validation error',
                eventLabel,
                nombre_formulario,
            },
        })
    }

    if (action == 'error') {
        dl.push({ formValidationError: null })
        dl.push({
            event: 'error_formulario',
            formValidationError: {
                eventCategory,
                eventAction: 'server error',
                eventLabel,
                nombre_formulario,
            },
        })
    }
}

export const bfcacheGTM = () => {
    if (!dl) return

    // Para trackear las páginas que se cargan vía bfcache
    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
            window.dataLayer.push({
                event: 'trackpageview',
                navigation_type: 'back_forward_cache',
            })
        }
    })
}
